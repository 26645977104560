.call-ui {
    .video-group {
        position: absolute;
        top: 72px;
        left: 0px;
        height: calc(100% - 160px);
        width: 100%;
        overflow: hidden;

        .video-container {    
            position: absolute;
            background-color: black;

            &#incoming-vid-stream-container {
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
            }

            &#outgoing-vid-stream-container {
                top: 1rem;
                right: 1rem;
                width: 20%;
                height: 20%;
                overflow: hidden;
                transform: rotateY(180deg);
                -webkit-transform: rotateY(180deg); /* Safari and Chrome */
                -moz-transform: rotateY(180deg); /* Firefox */
            }
            .video {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                height: 100%;
                top: 0px;
                
            }
        
        }


    }

    .call-controls {
        position: fixed;
        bottom: calc(6rem + 7vh);
        left: 0px;
        width: 100%;
        &.optional {
            bottom: calc(10rem + 7vh);
        }
        
        .control {
            position: absolute;
            width: 5rem;
            height: 5rem;
            
            color: white;
            font-size: 5rem;
            bottom:0px;
    
            &.mic {
                left: 2rem;
            }
            
            &.cam {
                right: 2rem;
            }
            
        }
    }
}
