.login-page {
    .logo {
        * {
            line-height: 3rem;
            vertical-align: middle;
        }
        .logo-image {
            height: 3rem;
            padding-right: 0.66rem;
        }
    }
    .form {
        .field {
            margin-bottom: 1rem;
            
        }

        .forgotten-link {
            margin-bottom: 2rem;
        }

        .social-button {
            margin: auto;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }
}