.bottom-nav {
    align-items: center;
    color: #000;
    background-color: white;

    .nav {
        max-width: unset !important;
        text-align:center;
        color: #000;
    
    }

    .center-nav {
        &.home {
            &>div {
                bottom: 1rem;
                padding: 2rem;
                font-size: 5rem;
            }
            .home-icon {
                top: 0em;
                position: relative;
            }

        }
        
        &>div {
            position:relative;
            bottom: 3rem;
            padding: 4rem;
        }

        .home-icon {
            width: 3.8em;
            height: 3.8em;
            top: 0.35em;
            position: relative;
        }
        
    }
}