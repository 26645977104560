.friend-requests {
    display:block;
    width: 80px;
    margin: auto;

    .snack-bar {
        top: 80px;
    }

    .awaiting-friendship {
        position: absolute;
        width: 80px !important;
        height: 80px !important;
        bottom: 0px;
        margin:auto;
        display:block;
    }
}