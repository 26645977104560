.field-wrapper {
    display: flex;
    justify-content:center;
    margin-bottom: 1.6rem;
    &>*:not(.avatar) {
        flex-grow: 1;
    }

    .avatar {
        opacity: 1;
        
        &:hover {
            opacity: 0.7;
        }
    }
    
}